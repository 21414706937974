import Provider from '../settings/provider-display.json';
import Service from '../settings/service-display.json';

const useDisplayFields = () => {
  const service = JSON.parse(JSON.stringify(Service));
  const provider = JSON.parse(JSON.stringify(Provider));

  provider.teaser.fields = provider.teaser.fields.map(field => (
    (typeof field === 'string') ? { field } : field
  ));

  service.teaser.fields = service.teaser.fields.map(field => (
    (typeof field === 'string') ? { field } : field
  ));

  return {
    provider,
    service,
  };
};

export default useDisplayFields;
