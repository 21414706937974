/* eslint-disable import/prefer-default-export */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useGalleries = () => {
  const { wrapper: { nodes } } = useStaticQuery(graphql`
    query {
      wrapper: allFile(filter: {
        sourceInstanceName: {eq: "data-file"}
        relativeDirectory: {eq: "galleries"}
      }) {
        nodes {
          name
          internal { content }
        }
      }
    }
  `);

  return React.useMemo(() => nodes.reduce((acc, node) => {
    const { name, internal: { content } = {} } = node;

    try {
      return { ...acc, [name]: JSON.parse(content) };
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return { ...acc, [name]: content };
    }
  }, {}), [nodes]);
};

export default useGalleries;
