import React from 'react';
import clsx from 'clsx';

import { withPrefix } from 'gatsby';

import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby-material-ui-components';

import useGalleries from '../hooks/useGalleries';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageWrapper: {
    position: 'absolute',
    inset: 0,
  },
  image: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imageContain: {
    objectFit: 'contain',
  },
  imageCover: {
    objectFit: 'cover',
  },
}));

const ImageGrid = ({ className, gallery, manualItems = [], ...gridProps }) => {
  const { [gallery]: gallerySettings } = useGalleries();
  const large = useMediaQuery(theme => theme.breakpoints.up('md'));

  const {
    items = manualItems,
    gridProps: customGridProps = {},
    itemProps: customItemProps = {},
  } = gallerySettings;

  const classes = useStyles();

  return (
    <Box className={clsx(className, classes.root)}>
      <ImageList
        rowHeight={200}
        cols={large ? 4 : 2}
        {...customGridProps}
        {...gridProps}
      >
        {items.map(({ primary, secondary, image, cols = 1, href, fill, ...itemProps }) => (
          <ImageListItem
            key={primary}
            cols={cols}
            {...customItemProps}
            {...itemProps}
          >
            <Box className={classes.imageWrapper}>
              <img
                src={withPrefix(image)}
                alt={primary}
                className={clsx(classes.image, {
                  [classes.imageContain]: fill === 'contain',
                  [classes.imageCover]: fill === 'cover',
                })}
              />
            </Box>

            {href ? (
              <Link to={href}>
                <ImageListItemBar title={primary} subtitle={secondary} />
              </Link>
            ) : (
              <ImageListItemBar title={primary} subtitle={secondary} />
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

export default ImageGrid;
