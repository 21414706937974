import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'gatsby-material-ui-components';

import Layout from '../components/Layout';
import Link from '../components/Link';

import useCorrectLanguage from '../hooks/useCorrectLanguage';
import usePlatforms from '../hooks/usePlatforms';
import PlatformList from '../components/PlatformList';

import blueBg from '../assets/computing.png';
import PCSCButton from '../components/PCSCButton';
import ImageGrid from '../components/ImageGrid';

const TempSelect = ({ label, ...props }) => (
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={10}
    {...props}
  >
    <MenuItem value={10}>{label}</MenuItem>
  </Select>
);

const useStyles = makeStyles(theme => {
  const { palette: { primary, secondary } } = theme;

  return {
    layout: {
      marginTop: 0,
      padding: 0,
    },

    blue: {
      textAlign: 'right',
      background: `linear-gradient(90deg, ${primary.main} 50%, ${secondary.main} 50%)`,
      color: secondary.contrastText,
      '& .MuiTypography-h1': {
        fontWeight: 'bold',
      },
      marginBottom: theme.spacing(3),
    },

    blueInner: {
      backgroundImage: `url(${blueBg})`,
      backgroundPosition: 'top left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(16),

      '& h1': {
        textTransform: 'uppercase',
      },
      '& h6': {
        fontSize: '1.35rem',
        lineHeight: 1.4,
      },
    },

    heroDivider: {
      height: 1,
      background: secondary.contrastText,
      maxWidth: '32rem',
      marginLeft: 'auto',
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },

    simpleSearch: {
      // paddingTop: theme.spacing(0),
      // paddingBottom: theme.spacing(4),
    },

    simpleSearchButtons: {
      marginTop: theme.spacing(2),
    },

    platformList: {
      marginTop: theme.spacing(10),
    },

    platformListInner: {
      height: 300,
      overflow: 'auto',
      border: '1px solid gray',
      borderRadius: theme.shape.borderRadius,
    },

    platformListButtonWrapper: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },

    useCases: {
      display: 'none',
      position: 'relative',
      paddingBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),

      color: primary.contrastText,

      '&::before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        top: -50,
        bottom: -20,
        left: 0,
        right: 0,
        background: secondary.main,
        transform: 'skew(0, -2deg)',
        zIndex: -1,
      },

      '& h2': {
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
    },

    useCasesAvatar: {
      backgroundColor: secondary.contrastText,
      color: '#ccc',
      width: theme.spacing(20),
      height: theme.spacing(20),
      marginLeft: 'auto',
    },

    useCasesForm: {
      fontSize: '2rem',
    },

    useCasesButtonWrapper: {
      textAlign: 'right',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },

    personas: {

    },

    supporters: {
      marginTop: theme.spacing(10),
    },

    helpNav: {
      display: 'none',
      color: primary.contrastText,
      background: primary.main,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),

      fontSize: '2rem',
      fontWeight: 200,
    },
  };
});

const Home = ({ pageContext: { language } }) => {
  const { t } = useTranslation();
  useCorrectLanguage(language);

  const platforms = usePlatforms();
  const classes = useStyles();

  return (
    <Layout translationsAvailable container={false} className={classes.layout}>
      <Box className={classes.blue}>
        <Container className={classes.blueInner}>
          <Typography variant="h1">
            <Trans>
              Portail Calcul, Stockage et Cloud
            </Trans>
          </Typography>

          <Divider
            variant="fullWidth"
            className={classes.heroDivider}
          />

          <Typography variant="subtitle2" gutterBottom>
            <Trans>
              Trouvez rapidement<br />
              des infrastructures qui vous correspondent
            </Trans>
          </Typography>
        </Container>
      </Box>

      <Container className={classes.simpleSearch}>
        <Typography variant="h2">
          <Trans>
            Vous recherchez un service pour du…
          </Trans>
        </Typography>

        <Grid container justifyContent="space-evenly" className={classes.simpleSearchButtons}>
          <Grid item>
            <Button to="/oad" variant="contained" color="secondary">{t('Calcul')}</Button>
          </Grid>
          <Grid item>
            <Button to="/oad" variant="contained" color="secondary">{t('Stockage')}</Button>
          </Grid>
          <Grid item>
            <Button to="/oad" variant="contained" color="secondary">{t('Cloud')}</Button>
          </Grid>
        </Grid>
      </Container>

      <Container className={classes.platformList}>
        <Box className={classes.platformListInner}>
          <PlatformList platforms={platforms} />
        </Box>

        <Box className={classes.platformListButtonWrapper}>
          <PCSCButton to="/oad" component={Link}>
            {t('Voir l\'ensemble des sites')}
          </PCSCButton>
        </Box>
      </Container>

      <Container className={classes.supporters}>
        <Typography variant="h2">
          Ils ont soutenu le 1er Hackathon
        </Typography>

        <ImageGrid gallery="gallery-01" />
      </Container>

      <Container className={classes.useCases}>
        <Typography variant="h2">
          <Trans>
            Définir son besoin & cas d'usage…
          </Trans>
        </Typography>

        <Grid
          container
          spacing={6}
          alignItems="center"
          style={{ marginTop: 20, lineHeight: 1.6 }}
        >
          <Grid item xs={4}>
            <Avatar className={classes.useCasesAvatar} />
          </Grid>

          <Grid item xs={8} className={classes.useCasesForm}>
            Je suis <TempSelect label="Bio informaticien" /><br />
            j'ai besoin de <TempSelect label="Stockage" /><br />
            pour une  <TempSelect label="Grande (1 Po)" /> de données.
          </Grid>
        </Grid>

        <Box className={classes.useCasesButtonWrapper}>
          <PCSCButton to="/oad" component={Link}>
            {t('Voir mes résultats')}
          </PCSCButton>
        </Box>
      </Container>
      <Container className={classes.helpNav}>
        <Box><Link color="inherit" to="/docs/definitions">Définitions</Link></Box>
        <Box><Link color="inherit" to="/docs/vocabulaire">Vocabulaire</Link></Box>
        <Box><Link color="inherit" to="/docs/types-de-calcul">Différents types de calcul</Link></Box>
        <Box><Link color="inherit" to="/docs/contribuer">Contribuer</Link></Box>
      </Container>
    </Layout>
  );
};

export default Home;
