import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'gatsby-material-ui-components';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: 0,
    boxShadow: theme.shadows[2],
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),

    letterSpacing: 2,
    '&:hover': {
      background: '#f0f0f0',
      boxShadow: theme.shadows[4],
    },
  },

  label: {
    textTransform: 'none',
  },
}));

const PCSCButton = ({ classes, ...rest }) => {
  const localClasses = useStyles();

  return (
    <Button
      classes={{
        root: localClasses.root,
        label: localClasses.label,
        ...classes,
      }}
      {...rest}
    />
  );
};

export default PCSCButton;
